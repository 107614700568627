/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
    font-size: 18px;
    font-size: 1rem;
    position: relative;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    width: 100%;
    text-align: left;
    transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    background-color: rgba(255, 255, 255, 0.9);

}
.geosuggest:hover {
    background-color: rgba(255, 255, 255, 1);
}
.geosuggest__input {
    width: 100%;
    font-size: 1rem;
    outline: none;
    padding: 14px 10px;
    border:1px solid rgba(0, 0, 0, 0.23);
border-radius: 4px;
}
.geosuggest__input:focus {
    box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}
.geosuggest__label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    background: #fff;
    padding: 0px 4px;
    margin-bottom: -12px;
    display: inline-block;
    z-index: 4;
    position: relative;
    left: 10px;
    top: 10px;
}
/**
   * A geosuggest item
   */
.geosuggest__item {
    font-size: 18px;
    font-size: 1rem;
    padding: 0.5em 0.65em;
    cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
    background: #f5f5f5;
}
.geosuggest__item--active {
    color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
    background: #ccc;
}
.geosuggest__item__matched-text {
    font-weight: bold;
}
